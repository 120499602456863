.project_box{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    // a{
    //     text-decoration: none;
    //     color: #222;
    // }
}
.pj_item{
    width: 265px;
    height: 265px;
    background-color: #F1F1F1;
    position: relative;
    cursor: pointer;
    margin: 0 10px 28px;
    &:hover .pj_hover{
        opacity: 0.75;
    }
}
.pj_hover{
    width: 265px;
    height: 265px;
    background-color: #000;
    color: #FFF;
    padding: 15px;
    box-sizing: border-box;
    font-size: 22px;
    letter-spacing: 1px;
    line-height: 1.5;
    position: absolute;
    top: 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    opacity: 0;
    p:first-child {
        text-shadow: 0px 0px 3px #000;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
    }
}
.pj_img{
    background-color: #E3E3E3;
    height: 180px;
    /*background-image: url(好自為之.jpg);*/
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0.9;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
      max-width: 100%;
      max-height: 180px;
    }
}
.pj_title{
    padding: 15px 12px;
    box-sizing: border-box;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 1.2;
    height: 85px;
    display: flex;
    align-items: center;
    justify-content: center;
    p{
        overflow: hidden;
        /*text-overflow: clip;*/
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }
    .stamp_jun{
        font-family:DFKai-sb;
        border: 1px solid #F00;
        width: 20px;
        height: 20px;
        text-align: center;
        line-height: 18px;
        font-size: 18px;
        border-radius: 50%;
        color: #F33;
        display: inline-block;
        vertical-align: top;
        margin-right: 5px;
        transform: rotateZ(12deg);
    }
}
.fa_arrow{
    display: flex;
    height: 50px;
    justify-content: center;
    margin-top: 30px;
}

@media screen and (max-width: 768px) {
    .project_box{
        justify-content: space-around;
        margin: 55px 0;
    }
}