.account{
	width: 100%;
	height: 40px;
	box-shadow: 0px 1px 3px 0px #CCCC;
}
.float{
	float: right;
}
#hamburgerIcon{
	display: none;
}
.showname{
	display: inline-block;
	height: 40px;
	padding: 0px 12px;
	color: #888;
	line-height: 40px;
}
.membericon{
	display: inline-block;
    vertical-align: top;
	.member_submenu{
		opacity: 0;
		transform: translateY(-5%);
		z-index: -1;
		transition: .7s ease-out;
	}
	&:hover .member_submenu{
		opacity: 1;
		transform: translateY(0%);
		z-index: 1;
	}
}
.membericon_frame{
	display: inline-block;
	height: 40px;
	width: 40px;
	background-color: #000;
	color: #A7A5A6;
	text-align: center;
	line-height: 40px;
}
.member_submenu{
	position: absolute;
    top: 40px;
    right: 0px;
    // background-color: #ED700022;
    background-color: #F8C69999;
    // background-color: #F4A96699;
    padding: 20px 20px;
    min-width: 110px;
    // min-height: 140px;
    min-height: 77px;
	ul li{
		padding: 8px 0;
		a{
			display: block;
			text-decoration: none;
			color: #333;
			font-weight: 700;
			&:hover{
				color: #ED7000;
			}
		}
	}
}

// ===== RWD =====

@media screen and (max-width: 768px) {
	.sideBar, .float, .LOGO {
		display: none;
	}
	#hamburgerIcon{
		display: inline-block;
    	font-size: 1.5rem;
    	position: relative;
    	top: 4px;
    	left: 8px;
	}
	.sideBar_ham{
		min-height: calc(100vh - 80px);
		width: 240px;
		background-color: #221F20EE;
		position: absolute;
		// left: 0;
		top: 40px;

		display: block;
		// left: -240px;
		position: fixed;
    	min-height: calc(100vh);
    	top: 40px;
    	z-index: 1;

	}

	.showname_ham{
		color: #8D8B8B;
		line-height: 1.25;
		font-weight: 700;
		padding: 15px 0;
		p {
			border-bottom: 1px solid #8D8B8B99;
			padding: 5px 0;
			max-width: 150px;
		}
	}
	.member_submenu_ham ul li a{
		text-decoration: none;
		color: #8D8B8B;
		line-height: 1.25;
		font-weight: 700;
		display: block;
		padding: 5px 0px;
		&:hover{
			color: #A7A5A6;
			text-shadow: 0px 0px 5px #7B7979;
		}
	}
	.a_toPjList_ham {
		text-decoration: none;
		color: #A7A5A6;
		display: block;
		margin: 15px 0 27px 0;
		padding: 0 6px;
		position: relative;
	}

	.a_routerDad p{
		padding-top: 7px;
	}
	.iconbox_ham{
		position: absolute;
		right: -3px;
		top: 2px;
		color: #7B7979;
		padding: 4px 5px;
		margin: 0;
		font-size: 14px;
	}

	.account{
		position: fixed;
    	top: 0;
    	background-color: #FDFDFD;
    	z-index: 1;
	}
}
