.sharebox_float{
    background-color: #fff;
    box-shadow: 0 1px 3px 0px #aaaa;
    width: 500px;
    margin: 50px calc(50% - 250px);
    padding: 20px;
    box-sizing: border-box;
    .auth{
        .LOGO{
            background-color: transparent;
        }
        h1{
            text-align: center;
            font-size: 24px;
            line-height: 1.8;
            margin: 30px 0 15px 0;
            font-weight: 700;
            background-color: #000;
            color: #FFF;
        }
        .form{
            font-size: 16px;
            padding: 10px;
            input[type="text"]{
                width: 100%;
                padding: 0 3px;
            }
            input[type="password"]{
                width: 100%;
                height: 28px;
                border-radius: 3px;
                border: 1px solid #999;
                padding: 0 3px;
                box-sizing: border-box;
            }
            .form_title{
                width: 4em;
            }
            .form_input{
                width: calc(100% - 4em);
                label{
                    width: unset;
                }
            }

            .auth_hint{
                padding: 5px 7px 5px 4em;
                .auth_hint_remember{
                    // float: left;
                    display: inline-block;
                    color: #A7A5A6;
                    input[type="checkbox"]{
                        margin-right: 3px;
                    }
                }
                .auth_hint_forget{
                    float: right;
                    display: inline-block;
                    .a_forgetpassword{
                        text-decoration: none;
                        color: #A7A5A6;
                        &:hover{
                            color: #353535;
                        }
                    }
                }
            }
            .auth_loginbtn{
                clear: both;
                margin: 35px 0 15px 0;
                text-align: center;
            }

        }
    }
}

@media screen and (max-width: 1000px) {
    .sharebox_float{
        width: 80%;
        margin: 50px 10%;
    }
}
@media screen and (max-width: 415px) {
    .sharebox_float{
        width: 90%;
        margin: 50px 5%;
        .auth{
            .form{
                padding: 0;
            }
            h1{
                margin: 25px 0 20px 0;
            }
        }
    }
}
