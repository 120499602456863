.sideBar{
	min-height: 100vh;
	width: 210px;
	background-color: #221F20;
	position: fixed;
	left: 0;
	top: 0;
}

.iconbox{
	position: absolute;
    right: -3px;
    top: 2px;
    color: #7B7979;
    padding: 4px 5px;
    margin: 0;
    font-size: 14px;
}

.LOGO{
	height: 40px;
	background-color: #353535;
	img{
		position: relative;
		top: calc(10px);
		left: 16px;
	}
}
.routerMenu{
	margin: 20px 0;
	padding: 15px;
    height: calc(100vh - 60px);
	overflow: auto;
}
/*--- 滾動條整體樣式(捲軸寬度) ---*/
.routerMenu::-webkit-scrollbar {
	width: 5px;
	height: 5px;
}
/*--- 滾動條裡面小方塊樣式(捲軸本體顏色) ---*/
.routerMenu::-webkit-scrollbar-thumb {
	border-radius: 4px;
	background-color: #A7A5A6;
}
/*--- 滾動條裡面軌道樣式(捲軸底色) ---*/
.routerMenu::-webkit-scrollbar-track {
	background-color: #221F20;
}

.a_toPjList{
	text-decoration: none;
	color: #A7A5A6;
	display: block;
	margin: 8px 0 30px 0;
    padding: 0 6px;
    position: relative;
}
.a_toPjList:hover{
	color: #A7A5A6;
	text-shadow: 0px 0px 5px #7B7979;
}

.li_router_box{
	margin: 20px 0 20px 0;
	border-left: 2px solid #7B797999;
	padding: 0 6px;
	position: relative;
}
.a_routerDad{
	text-decoration: none;
	/*color: #7B7979;*/
	color: #8D8B8B;
	line-height: 1.25;
	font-weight: 700;
}
.a_routerDad p{
	width: 136px;
	padding-top: 8px;
}
.a_routerDad:hover{
	color: #A7A5A6;
	text-shadow: 0px 0px 5px #7B7979;
}
.current{
	color: #D3D2D2;
}

.ul_routerSon{
	margin: 10px 0 25px 0;
	color: #A7A5A6;
    font-size: 14px;
}
.ul_routerSon li{
	padding: 10px 0 2px 0;
}
.ul_routerSon li a{
	text-decoration: none;
	color: #A7A5A6;
    font-size: 14px;
	padding: 6px 0;
}
.ul_routerSon li a:hover{
	color: #D3D2D2;
}
// .ul_routerSon li a:focus{
// 	color: #007DA3;
// }
.active{
	color: #007DA3 !important;
}

// ===

// .ul_routerSon{
// 	-webkit-transition: 0.6s;
// 	transition: all 1.6s;
// 	// display: none;
// 	overflow: hidden;
// 	height: 0;
// }

.off+.ul_routerSon {
	-webkit-transition: 0.6s;
	transition: all 1.6s;
	display: none;
	// overflow: hidden;
	// height: 0;
}
.current+.ul_routerSon {
	-webkit-transition: 0.6s;
	transition: all 1.6s;
	display: block;
	// overflow: hidden;
	// height: 150px;
}

// ===== rwd =====


@media screen and (min-width: 769px) {

.sideBar_ham{
	display: none;
		// min-height: calc(100vh - 80px);
		// width: 240px;
		// background-color: #221F20EE;
		// position: absolute;
		// left: 0;
		// top: 40px;
	}
}
@media screen and (max-width: 768px) {}