$dashboardTextColor: #e6e6e6; //內容文字色
$boxColorGray: #555555; //區塊背景色
$containerColor: #666666; //main顏色

$darkTextColor: #0c0c0c;
$darkContainerColor: #2f2f2f;
$darkBoxColor: #ffffff;

.containerBox {
    width: 100%;
    background-color: $containerColor;
    box-sizing: border-box;
    padding: 8px 10px 10px 10px;
    margin: -15px 0 0 0;
}
.dashboardUpArea{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 0 10px;
    .dashboardUpArea_left{
        width: 100%;
        display: flex;
        justify-content: end;
        align-items: center;
        padding: 10px 0px 0px;
        @media screen and (max-width: 960px) {
            flex-direction: column;
            align-items: flex-start;
            padding: 10px 0px 0px;
        }
    }
}
.dashboardTitle {
    h1 {
        font-size: 20px;
        font-weight: 700;
        color: #f28e2b;
    }
}
.projectWrap {
    width: 100%;
    height: 43px;
    background-color: $boxColorGray;
    margin: 10px 0px 10px;
    h3 {
        color: $dashboardTextColor;
        font-size: 14px;
        padding: 4px 6px 2px 6px;
    }
    @media screen and (max-width: 1200px) {
        // width: 100%;
    }
}
.dropdownBox{
    position: relative;
    .dropdownMenu{
        width: 100%;
        font-size: 14px;
        border-radius: 0px;
        outline: none;
        text-overflow: ellipsis;
        background-color: #bcbcbc;
        &:active {
          outline: none;
        }
    }
}
.subTitle {
    // display: flex;
    // align-items: center;
    font-size: 18px;
    font-weight: 700;
    color: $dashboardTextColor;
    // h1 {
    //     width: 100%;
    //     text-align: end;
    // }
    // @media screen and (max-width: 960px) {
    //     width: 100%;
    //     margin-top: 5px;
    //     h1 {
    //         text-align: start;
    //     }
    // }
}
// 深色模式切換按鈕
.toggleDarkMode{
    width: 100%;
    text-align: end;
    // margin-bottom: 5px;
    position: relative;
    top: -2em;
    .switchBox {
        position: relative;
        display: inline-block;
        vertical-align:middle;
        width: 60px;
        height: 30px;
        margin-right: 5px;
        cursor:pointer;
        .switchBg {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 60px;
            height: 30px;
            border-radius: 34px;
            background-color: #ccc;
            box-shadow: 0px 3px 0px rgba(0,0,0,.13) inset;
            -webkit-transition: .4s;
            transition: .4s;
        }
        .switchBtn {
            position: absolute;
            height: 25px;
            width: 25px;
            left: 0px;
            top: 3px;
            border-radius: 50%;
            background-color: #ffffff;
            box-shadow:1px 2px 5px rgba(0,0,0,.3);
            -webkit-transition: .4s;
            transition: .4s;
            &.clicked{
                transform: translateX(30px);
            }
        }
    }
}

//D3圖表區塊
.chartBlock{
    margin: 10px 0;
}

// ----- RWD -----
@media screen and (max-width: 768px) {
    .containerBox {
        width: 100%;
        background-color: #666666;
        box-sizing: border-box;
        padding: 0px;
        margin: 30px auto;
    }
    .dashboardUpArea {
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding: 7px;
    }
    .dashboardTitle {
        width: 99%;
        margin: 10px auto;
    }
    .projectWrap {
        // width: 99%;
        // margin: 0 10px;
        h3 {
            padding: 5px 5px;
          }
    }
    // .subTitle {
    //     width: 100%;
    // }
    .toggleDarkMode {
        // margin-top: 25px;
        top: 1.25em;
    }
}
@media screen and (max-width: 375px) {
    .containerBox {
        width: 375px;
    }
    .dashboardTitle {
        width: 375px;
    }
    .projectWrap {
        width: 375px;
    }
    // .subTitle {
    //     width: 375px;
    // }
}

// 深色模式
// @media (prefers-color-scheme: dark) {
//     .containerBox {
//         background-color: $darkContainerColor;
//     }
//     .dashboardTitle {
//         background-color: $darkBoxColor;
//         h1 {
//             color: #f28e2b;
//         }
//     }
//     .projectWrap {
//         background-color: $darkBoxColor;
//         h3 {
//             color: $darkTextColor;
//         }
//     }
//     .dropdownBox{
//         margin: 0 1px;
//         .dropdownMenu{
//             background-color: #c0c0c0;
//         }
//     }
//     .subTitle {
//         color: white;
//     }

// }
