.form{
	width: 100%;
	padding: 30px 45px 25px 20px;
	box-sizing: border-box;
	font-size: 0px;
	.form_half{
		input{
			width: 100%;
		}
	}
	label{
		margin-right: 5px;
		position: relative;
		top: 2px;
		display: block;
    width: fit-content;
	}
	input[type="checkbox"]{
		margin: 3px 4px;
	}
	input[type="text"]{
		height: 28px;
		border-radius: 3px;
		border: 1px solid #999;
	}
}

.form_loose{
  .form_half div, .form_13quarters div {
    padding: 0.75rem 5px;
  }
  input[type="text"]{
		margin: 0 3px;
	}
}

.form_half div .form_title,
.form_half div .form_input,
.form_half .form_title,
.form_half .form_input,
.form_13quarters .form_title,
.form_13quarters .form_input{
	display: inline-block;
	font-size: 1rem;
	line-height: 1.8;
	padding: 2px 5px;
	box-sizing: border-box;
	margin-bottom: 3px;
}
.form_half{
	width: 50%;
	display: inline-block;

	.form_title{
		width: 40%;
		text-align: right;
	}
	.form_input{
		width: 60%;
	}
}
.form_13quarters{
	width: 100%;
	display: inline-block;
	.form_title{
		width: 20%;
		text-align: right;
		vertical-align: top;
	}
	.form_input{
		width: 80%;
	}
}

.forReactSelect{
   div{
     padding: 0 2px !important;
   }
}

.table{
	table{
		width: 100%;
		background-color: #fff;
		text-align: center;
		margin: 1.5rem 0;
    box-shadow: 0 1px 3px 0px #AAAA;
    font-size: 16px;
		tr{
			box-shadow: 0 1px 2px 0px #CCCC;
      &:first-child{
        background-color: #E3E3E3;
        box-shadow: 0 1px 2px 0px #BBBB;
        font-size: 1.1rem;
      }
      &:not(:first-child):hover{
        background-color: #FDF1E6;
      }
		}
    td{
      padding: 1rem 4px;
      overflow-wrap: anywhere;
      vertical-align: middle;
      max-width: 360px;
      a{
        text-decoration: none;
        color: #007DA3;
      }
    }
	}
}
.table_ordered{
	table{
		tr{
      &:not(:first-child){
        td{
          &:first-child{
            border-right: 1px solid #CCCC;
          }
        }
      }
		}
	}
}
.table_dark {
  background-color: #444;
  table {
    width: 100%;
    color: #FFF;
    text-align: center;
    margin: 1rem 0;
    // box-shadow: 0 0 0 1px #999;
    font-size: 14px;
    table-Layout: fixed;
    tr {
      background-color: #444;
      // box-shadow: 0 0 2px 0 #CCC;
      border-top: 2px solid #cccccc86;
      &:not(:first-child):hover {
        background-color: #555;
      }
      &:first-of-type {
        position: sticky;
        top: 0;
        background-color: #444;
      }
      &:last-of-type {
        font-weight: 900;
        text-shadow: 1px 1px 3px #000;
        border-top: 1px solid;
        border-bottom: 1px solid;
      }
    }
    td {
      padding: 1rem 4px;
      overflow-wrap: anywhere;
      vertical-align: middle;
      max-width: 360px;
      a {
        text-decoration: none;
        color: #007DA3;
      }
    }
  }
}
.table_light {
  background-color: #FFF;
  table {
    width: 100%;
    color: #000;
    text-align: center;
    margin: 1rem 0;
    // box-shadow: 0 0 0 1px #999;
    font-size: 14px;
    table-Layout: fixed;
    tr {
      // box-shadow: 0 0 2px 0 #CCC;
      background-color: #FFF;
      border-top: 2px solid #cccccc98;
      &:not(:first-child):hover {
        background-color: #ECECEC;
      }
      &:first-of-type {
        position: sticky;
        top: 0;
        background-color: #FFF;
      }
      &:last-of-type {
        font-weight: 700;
        // text-shadow: 1px 1px 3px #000;
        border-top: 1px solid;
        border-bottom: 1px solid;
      }
    }

    td {
      padding: 1rem 4px;
      overflow-wrap: anywhere;
      vertical-align: middle;
      max-width: 360px;
      // background-color: #FFF;
      a {
        text-decoration: none;
        color: #007DA3;
      }
    }
  }
}

.filter_table{
  font-size: 16px;
  div{
    display: inline-block;
    padding: 0 5px;
    margin: 8px 0;
    box-sizing: border-box;
  }
  .filter_table_btn, .filter_table_btn_main{
    position: relative;
    top: -1px;
    margin: 0;
  }
  input[type="text"], select{
    height: 34px;
    border-radius: 3px;
    border: 1px solid #999;
    min-width: 15em;
  }
  select{
    height: 34px;
  }
  input[type="file"]{
    border: 1px solid #999;
    height: 28px;
    border-radius: 3px;
    padding: 2px;
  }
}

.popupCancelBtn{
	width: 100%;
	text-align: right;
  button{
  	font-size: 20px;
  	min-width: 45px;
    margin-top: 3px;
  }
}

@media screen and (max-width: 825px) {
  .filter_table .filter_table_btn_main{
    display: block;
    padding: 0;
    top: 0;
    margin-top: 5px;
    button{
      margin: 0 3px 0 0;
    }
  }
}

@media screen and (max-width: 450px) {
  .form{
    padding: 25px 10px;
    div{
      box-sizing: border-box;
    }
    .form_half, .form_13quarters{
      width: 100%;
      margin-bottom: 0.5rem;
      padding: 10px 8px 10px 8px;
      background-color: #ECECEC;
      border-radius: 5px;
    }
    .form_half div, .form_13quarters div{
      padding: 0 3px;
    }
    .form_half .form_title, .form_13quarters .form_title{
      width: 100%;
      text-align: left;
    }
    .form_half .form_input, .form_13quarters .form_input{
      width: 100%;
    }
  }
}

@media screen and (max-width: 450px) {
  .table{
    table{
      .tr_indark{
        &:first-child{
          background-color: #FAFAFA;
        }
      }
    }
  }
  .filter_table{
    border-bottom: 1px dotted #AAA;
    padding: 0 0 8px 0;
    margin-bottom: 2em;
    div{
      margin: 5px 0 10px 0;
    }
    // >div{}
    .filter_table_input{
      width: 100%;
      padding: 10px 3px 0px 3px;
      div{
        width: 100%;
        input[type="text"]{
          width: 100%;
        }
      }
    }
    .filter_table_btn{
      width: 100%;
      text-align: right;
      padding: 0 7px;
    }
    // .filter_table_btn_main{
    //   margin: 3px 0 6px 0;
    // }
  }
}
