body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

	background-color: #FDFDFD;
    /*font-family: Noto Sans TC,Microsoft JhengHei,Open Sans,verdana,sans-serif;*/
	/*position: relative;*/
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


* {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
html{
	font-size: 16px;
}

input[type="text"]{
	padding: 0 4px;
    box-sizing: border-box;
}

.container{
	width: 100%;
	min-height: calc(100vh - 40px);
}
.main{
	padding: 50px 30px 10px 240px;
	min-height: 100vh;
}
.sharebox{
	background-color: #fff;
	box-shadow: 0 1px 3px 0px #AAAA;
	margin: 1.5rem 0 2rem 0;
	padding: 10px;
    box-sizing: border-box;
}

.wholepage{
    padding: 20px;
    font-size: 16px;
}

.commontitle h1{
	font-size: 1.4rem;
	font-weight: 700;
	margin-bottom: 1rem;
}

.statusbar{
	background-color: #CCC;
	margin-bottom: 1rem;
	padding: 7px 12px;
	min-height: 10px;
	button{
		margin: 3px 3px;
	}
}

.pagesbar{
  @extend .statusbar;
  padding: 6px 8px;
	font-size: 16px;
}

// pagination

.page{
	li{
	  display: inline-block;
      text-align: center;
      min-width: 14px;
	  padding: 0 5px;
      height: 24px;
      border: 1px solid #CCC;
      background-color: #efefef;
      margin: 3px 3px;
    button{
      margin: 0;
      width: 100%;
    }
	}
  .li_PageFocus{
    background-color: #AAA;
    border: 1px solid #999;
    .PageFocus{
      background-color: #AAA;
      cursor: unset;
    }
  }
}

//////////

.bumper_rail{
	width: 100%;
	height: 40px;
  display: none;
}

.nowrap{
	white-space: nowrap;
}

.NeedSign{
	background-color: #FDF1E6AA;
}

//////////

.tabbox{
    box-shadow: 0 2px 1px -1px #007da3;
    padding: 0 0 0 1px;
}
.liDiv{
	display: inline-block;
	padding: 8px 15px;
	font-size: 1.2em;
	margin-right: 2px;
	margin-bottom: 2px;
	margin-top: 2px;
	border-right: 1px solid #CCC;
	border-left: 1px solid #CCC;
	border-top: 1px solid #CCC;
	cursor: pointer;
	border-radius: 5px 5px 0 0;
	/*box-shadow: 0px -2px 1px -1px #aaaa;*/
	color: #666;
	background-color: #F1F1F1;
}
.active_tooth{
	color: #007da3;
	border-right: 1px solid #007da3;
	border-left: 1px solid #007da3;
	border-top: 1px solid #007da3;
    /*background-color: #CCE5EDAA;*/
}
.liDiv:hover{
	color: #007da3;
    background-color: #CCE5EDAA;
}


/* ===================== button ===================== */

.a_fakebtn{
	border-radius: 4px;
	display: inline-block;
	margin: 3px;
	text-decoration: none;
}

.btn_box{
  	margin: 2rem 0 2rem 0;
	text-align: right;
	a{
		text-decoration: none;
		border-radius: 4px;
	}
}

button{
	cursor: pointer;
	border-radius: 4px;
	margin: 0 3px;
}
button:disabled {
	background-color: #F1F1F1;
  color: #999;
  border: 1px solid #999;
  cursor: not-allowed;
  &:hover{
    background-color: #F1F1F1;
    color: #999;
    border: 1px solid #999;
  }
}

.btnL{
	padding: 10px 12px;
	font-size: 16px;
    min-width: 4rem;
}
.btnM{
	padding: 6px 8px;
	font-size: 14px
}
.btnS{
	padding: 4px 6px;
	font-size: 12px
}
.btnWhite{
	background-color: #FFF;
	border: 1px solid #7B7979;
}
.btnWhite:hover{
	background-color: #F1F1F1;
	border: 1px solid #4E4C4C;
}
.btnGrey{
	background-color: #8D8B8B;
	border: 1px solid #7B7979;
	color: #FFF;
}
.btnGrey:hover{
	background-color: #7B7979;
	/*color: #CCE5ED;*/
}
.btnBlue{
	background-color: #CCE5ED;
	border: 1px solid #007DA3;
	color: #007DA3;
}
.btnBlue:hover{
	background-color: #007DA3;
	color: #CCE5ED;
}
.btnOrange{
	background-color: #FDF1E6;
	border: 1px solid #ED7000;
	color: #ED7000;
}
.btnOrange:hover{
	background-color: #ED7000;
	color: #FDF1E6;
}
.btnRed{
	background-color: #FEEFEF;
	border: 1px solid #f55753;
	color: #f55753;
}
.btnRed:hover{
	background-color: #f55753;
	color: #FEEFEF;
}

.icon_abnormal{
	margin-left: 3px;
	color: #ED7000AA;
	cursor: pointer;
}

.icon_needsign{
	margin-left: 4px;
	color: #AAA;
    font-size: 1.1rem;
}


@media screen and (max-width: 768px) {
	.main {
	    padding: 30px 10px 10px 15px;
	}
  .bumper_rail{
    display: block;
  }
}