.headtitle_pjname{
	width: 100%;
	background-color: #F1F1F1;
	padding: 15px 10px 0px 240px;
    box-sizing: border-box;
    border-top: 1px solid #FFF;
	h1{
		font-size: 1.4rem;
    	font-weight: 700;
		width: 90%;
    	overflow: hidden;
    	white-space: nowrap;
    	text-overflow: ellipsis;
	}
}
.nav{
	width: 100%;
	min-height: 60px;
	background-color: #F1F1F1;
	// padding: 10px 10px 10px 240px;
	padding: 5px 10px 10px 240px;
    box-sizing: border-box;
    // box-shadow: 0 3px 8px 0px #BBB9;
    // border-top: 1px solid #FFF;
    box-shadow: 0 6px 5px 0px #BBB9;
}
.queryMenu select{
	font-size: 1rem;
	margin: 5px 15px 5px 0;
	width: 400px;
	overflow: auto;
	border-radius: 3px;
	border: 1px solid rgba(0,0,0,.1);
    padding: 0 3px;
}
.queryMenu select option{
	margin: 1px 0;
	padding: 2px 0;
}
.queryMenu {
	button, .a_fakebtn{
		margin-bottom: 10px;
	  vertical-align: bottom;
    margin-right: 4px;
	}
}
.queryMenu_btnbox{
	display: inline-block;
	margin-top: 10px;
}

/*------------------------滾動條整體樣式(捲軸寬度)------------------------*/
.queryMenu select::-webkit-scrollbar {
	width: 5px;
	height: 5px;
}

/* ------------ 滾動條裡面小方塊樣式(捲軸本體顏色) ------------ */
.queryMenu select::-webkit-scrollbar-thumb {
	border-radius: 4px;
	background-color: #A7A5A6;
}

/* ---------------- 滾動條裡面軌道樣式(捲軸底色) ---------------- */
.queryMenu select::-webkit-scrollbar-track {
	background-color: #FFF;
}

@media screen and (max-width: 768px) {
	.headtitle_pjname{
		margin-top: 41px;
		padding: 15px 10px 0px 15px;
	}
    .nav{
		padding: 10px 10px 10px 15px;
	    // margin-top: 41px;
	}
	.queryMenu select{
		width: 100%;
		/*height: 80px;*/
	}
	.queryMenu_btnbox{
		width: 100%;
		text-align: right;
		padding: 0 0px;
    	box-sizing: border-box;
        margin-top: 5px;
	}
	.queryMenu_btnbox button, .queryMenu .a_fakebtn{
		margin-bottom: 5px;
	}
}