
.singlefile{
	background-color: #F1F1F1;
	display: inline-block;
	width: calc((100% / 3) - 20px);
	margin: 20px 10px;
  border-radius: 10px;
  padding: 29px 15px 20px 15px;
  box-sizing: border-box;
  position: relative;
  .singlefileImg{
    border: 2px dashed #BBB;
    text-align: center;
    height: 240px;
    margin-bottom: 10px;
    img{
    	max-width: 100%;
    	max-height: 100%;
    }
  }

  .flexImg{
  	display: flex;
  	justify-content: center;
    align-items: center;
  }

  .noImg img{
  	opacity: 0.3;
    width: 15%;
    height: 240px;
  }

  input{
	  width: 100%;
    box-sizing: border-box;
    margin: 8px 0;
    font-size: 16px;
    border: 1px solid #999;
  }
  input[type='file']{
    padding: 2px 2px;
    background: #DDD;
  }
  input[type='text']{
    line-height: 1.8;
    padding: 2px 6px 0px 7px;
  }
  input[type='file']:hover{
    cursor: pointer;
  }
  button{
  	margin: 8px 0 0 0;
  	width: 100%;
    opacity: 0.8;
  }
  .btnRed{
    position: absolute;
    right: 9px;
    top: 5px;
    width: 35px;
    opacity: 0.6;
    z-index: 1;
    &:hover {
      opacity: 1;
    }
    &:hover +.SingleFileShadow {
      display: block;
    }
  }
}

#popup {
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height:100%;
  z-index: 2;
  background: #3339;
  display: none;
}
#popupContent{
	position:absolute;
  width:80vw;
  // height:80vh;
  height:73vh;
  top: 50%;
  // left: 50%;
  left: 49%;
  margin-left: -40vw;
  margin-top: -40vh;
  background: #F9F9F9;
  z-index: 3;
  padding: 20px;
  overflow: auto;
  font-size: 16px;
}
.popupCancelBtn{
	width: 100%;
	text-align: right;
  button{
    font-size: 20px;
    min-width: 45px;
  }
}
.pdfIframe{
	margin: 15px 0;
	width: 100%;
	height: 100%;
}

.uploadfiles{
  @media screen and (max-width: 1500px) {
    .singlefile{
      width: calc((100% / 2) - 20px);
      margin: 20px 10px;
    }
  }
  @media screen and (max-width: 1045px) {
    .singlefile{
      .singlefileImg{
        height: 180px;
      }
    }
  }
  @media screen and (max-width: 920px) and (min-width: 769px) {
	.singlefile{
		width: calc((100%) - 20px);
    .singlefileImg{
      height: 240px;
    }
	}
}
  @media screen and (max-width: 630px) {
    .singlefile{
      width: calc((100%) - 20px);
      .singlefileImg{
        height: 240px;
      }
    }
  }
  @media screen and (max-width: 420px) {
    .singlefile{
      .singlefileImg{
        height: 180px;
      }
    }
  }
}

// signArea
.approver{
	margin-bottom: 10px;
}
.approverTitle{
	display: inline-block;
	width: 40%;
	min-width: 100px;
	height: 180px;
	vertical-align: middle;
	text-align: center;
	padding: 10px;
	box-sizing: border-box;
  .approverTitleInner{
    border-right: 1px solid #CCC;
    height: 100%;
    padding-top: 40px;
	  box-sizing: border-box;
  }
  p{
    font-size: 16px;
    line-height: 40px;
  }
  @media screen and (max-width: 420px) {
    width: 100%;
    height: 120px;
    .approverTitleInner{
      border-right: none;
      padding-top: 10px;
    }
  }
}
.approverSign{
	background-color: #F1F1F1;
	display: inline-block;
	width: 60%;
	height: 180px;
	vertical-align: middle;
	padding: 10px;
	box-sizing: border-box;
  text-align: center;
  img{
    max-width: 100%;
    max-height: 100%;
  }
  @media screen and (max-width: 420px) {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}