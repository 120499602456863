@media screen and (max-width: 1024px) {
  .table{
	  table{
		  button{
			  margin-bottom: 3px;
		  }
	  }
  }
}
@media screen and (max-width: 450px) {
  .filter_table{
    border-bottom: 2px solid #CCC;
    padding: 0 0 12px 0;
  }
}